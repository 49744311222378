import React, { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'rc-checkbox/assets/index.css';
import '../src/styles/variables.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import '@bepro-travel/fe.shared/dist/styles.css';
import '@bepro-travel/fe.hotel/dist/styles.css';
import '../src/styles/main.scss';
import '../src/styles/ofakim.scss';
import '../src/styles/enigma.scss';

import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { configureAxios, initSite } from './services/config';
import { MainContext } from './contexts/MainContext';
import { Hotel } from './ts/interfaces/HotelResponse';
import { ToastContainer } from 'react-toastify';
import { i18nconfig } from './utils/i18n';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  hotelConfig,
  mapSupplier,
  getSortHotelOptions,
  cdnUrl,
  hotelConfigImage,
} from './constants/generic';
import { HotelConfigContext, ProposalModal } from '@bepro-travel/fe.hotel';
import { initCSSVars, initLoader } from './utils/utils';
import { ScriptLoader, SharedConfigContext, User, auth } from '@bepro-travel/fe.shared';
import RouterConfig from './components/RouterConfig';

function App() {
  const [isAppReady, setIsAppReady] = useState(false);
  const [hotConfig, setHotConfig] = useState<any>(hotelConfig);
  const [proposals, setProposals] = useState<Hotel[]>([]);
  const [user, setUser] = useState<User | undefined>();
  const [recaptchaKey, setRecaptchaKey] = useState('');
  const [isEnigma, setIsEnigma] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [hotelViewConfig, setHotelViewConfig] = useState({
    areRoomsGrouped: false,
    areRoomsColoured: false,
    isRoomNameCleared: true,
    areRoomsFiltered: false,
    isCubeDesign: true,
    showRoomsInTab: false,
    isShortMode: true,
    isCubeDesignByEat: false,
    displayMode: 4,
    roomMode: 1,
  });

  const mainConfig = {
    proposals,
    setProposals,
    mapSupplier,
    user,
    setUser,
  };

  useEffect(() => {
    (async () => {
      await configureAxios();

      const user = await auth.getUserToken();
      const siteData = await initSite();
      if (siteData) {
        setRecaptchaKey(siteData.recapchaKey);
        initCSSVars(siteData);
        setIsEnigma(siteData.companyId === 135);
        setLogoUrl(siteData.logoUrl);

        if (siteData.other?.siteTitle) {
          document.title = siteData.other.siteTitle + ' - BePro Travel';
        }
      }

      if (user?.config?.hotel) {
        setHotelViewConfig(user.config.hotel);
      }

      i18nconfig();
      setUser(user);
      initLoader();
      setHotConfig({ ...hotelConfig, user, sortOptions: getSortHotelOptions() });

      setIsAppReady(true);
    })();
  }, []); // eslint-disable-line

  return (
    <BrowserRouter>
      <div className="app">
        {isAppReady && (
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <ScriptLoader>
              <SharedConfigContext.Provider
                value={{
                  cdnUrl,
                  setUser,
                  user,
                  defaultMultiSearchConfig: '',
                  setHeader: () => {},
                  setProposals,
                  proposals,
                  setFooter: () => {},
                  setSiteConfig: () => {},
                  setLanguage: () => {},
                  language: 'en',
                  SearchBox: () => <></>,
                  ProposalModal,
                  imgUs: './assets/img/us.png',
                  imgIs: './assets/img/is.png',
                  imgBg: './assets/img/newbg.jpg',
                  imgLogo: './assets/img/logo.png',
                  history: {
                    visible: false,
                  },
                  hotelConfig: hotelViewConfig,
                  setHotelConfig: setHotelViewConfig,
                  b2b: true,
                  hotelConfigImage,
                }}
              >
                <HotelConfigContext.Provider
                  value={{
                    ...hotConfig,
                    user,
                    hotelConfig: hotelViewConfig,
                    setHotelConfig: setHotelViewConfig,
                    showOrderCompanyId: isEnigma,
                    showOrderTripId: isEnigma,
                  }}
                >
                  <MainContext.Provider
                    value={{ ...mainConfig, setHotelConfig: setHotelViewConfig }}
                  >
                    <Header logoUrl={logoUrl} />
                    <RouterConfig isEnigma={isEnigma} />
                    <Footer logoUrl={logoUrl} />
                  </MainContext.Provider>
                </HotelConfigContext.Provider>
              </SharedConfigContext.Provider>
            </ScriptLoader>
          </GoogleReCaptchaProvider>
        )}
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
