import {
  BookHotelPage,
  BookPayment,
  ResultHotelPage,
  SuccessHotelPage,
} from '@bepro-travel/fe.hotel';
import { FC, Fragment, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { GroupRequestPage, MainMOPage, NotFoundPage, PreviewPage } from '@bepro-travel/fe.shared';
import MainPage from '../pages/MainPage';
import { MainContext } from '../contexts/MainContext';
import DetailsHotelPageModal from './DetailsHotelPageModal';
import Login from '../pages/Login';

interface RouterConfigProps {
  isEnigma: boolean;
}

const ResultHotel = () => {
  const { proposals, setProposals } = useContext(MainContext);
  return <ResultHotelPage proposals={proposals} setProposals={setProposals} />;
};
const RouterConfig: FC<RouterConfigProps> = ({ isEnigma }) => {
  const { pathname, ...location } = useLocation();
  const [urlParams, setUrlParams] = useSearchParams();
  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes('/hotel')) {
      setTimeout(() => {
        sessionStorage.setItem(
          'latestHotelPath',
          JSON.stringify({ ...location, pathname, time: new Date() })
        );
      }, 1000);
    }

    if ((!user || !user.isAuth) && pathname !== '/login') {
      navigate('/login');
    }
  }, [pathname]); // eslint-disable-line

  const onCloseDetails = () => {
    const newParams = Object.fromEntries(urlParams);
    delete newParams.details;

    setUrlParams(newParams);
  };

  return (
    <Fragment>
      <div style={{ display: pathname === '/' || pathname === '/hotels' ? 'block' : 'none' }}>
        <MainPage isEnigma={isEnigma} />
      </div>
      <div style={{ display: pathname === '/hotels/results' ? 'block' : 'none' }}>
        <ResultHotel />
      </div>
      <div style={{ display: location.search.includes('details=') ? 'block' : 'none' }}>
        <DetailsHotelPageModal onClose={onCloseDetails} />
      </div>
      <div style={{ display: pathname === '/hotel/book' ? 'block' : 'none' }}>
        <BookHotelPage />
      </div>
      <div style={{ display: pathname === '/hotel/payment' ? 'block' : 'none' }}>
        <BookPayment />
      </div>
      <div style={{ display: pathname === '/hotel/success' ? 'block' : 'none' }}>
        <SuccessHotelPage />
      </div>
      <div className="login-top" style={{ display: pathname === '/login' ? 'block' : 'none' }}>
        <Login />
      </div>
      <div style={{ display: pathname === '/my-orders' ? 'block' : 'none' }}>
        <MainMOPage />
      </div>
      <div style={{ display: pathname === '/group' ? 'block' : 'none' }}>
        <GroupRequestPage />
      </div>
      <div style={{ display: pathname === '/preview' ? 'block' : 'none' }}>
        <PreviewPage />
      </div>
      <div
        style={{
          display: ![
            '/',
            '/hotels',
            '/hotels/results',
            '/hotel/book',
            '/hotel/payment',
            '/hotel/success',
            '/login',
            '/my-orders',
            '/group',
            '/preview',
          ].includes(pathname)
            ? 'block'
            : 'none',
        }}
      >
        <NotFoundPage />
      </div>
    </Fragment>
  );
};

export default RouterConfig;
